<template>
  <div class="container" style="width:90%;color:#fff;">
    <h1><span @click="goHome" class="lLink" >LAZLO STEIGENBERGER</span> has written a book</h1>
    <div style="display:flex">
    <div @mouseover="hover=true" @mouseout="hover=false">
     <img  v-if="hover" src="../assets/images/BackCover.jpg" height="400px">
     <img  v-else src="../assets/images/FrontCover.jpg" height="400px">
     </div>
    <div style="width:90%;margin:auto;">
    <ol >
        <li>LAZLO CONSIDERS THE BOOK TO BE THE FIRST AND BEST MALE RESPONSE TO THE PANDEMIC OF MEN BEHAVING APPALLINGLY.</li>
        <li>WHETHER IT IS OR NOT - HE ISN'T INTERESTED</li>
        <li>LAZLO WILL BE LAUNCHING THE BOOK OFFICIALLY AT A LATER DATE</li>
        <li>LAZLO IS BUSY CONVINCING THE SPECIES OF “THE ABSENCE OF THEIR SPHERE'S MOTION IN THEIR EVERYDAY” BEING THE REASON THEIR LIVES ARE HEADING OFF THE RAILS</li>
        <li>THERE ARE MORE BOOKS COMING IN THIS RANGE AND COLOUR</li>
        <li>THIS IS MERELY THE BEGINNING</li>
    </ol>
    <ul >
        <li>TO CONTACT PLEASE WRITE - <a href="mailto:JSBARTSMANAGEMENT@GMAIL.COM">JSBARTSMANAGEMENT@GMAIL.COM</a></li>
    </ul>
     </div>
     </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  
  },
  data() {
    return {
        hover:false,
      audioFile:null,
      audioName:"XXXX",
      player:null,
      playerIndex:null,
      pageScale:1,
      startTime:null,
      distance:0,
      showConsole:false,
      types:{mini:{name:"Lazlo Minis",typ:"mini"},
            short:{name:"Lazlo Shorts",typ:"short"},
            long:{name:"Lazlo Elongated",typ:"long"}},
      podCasts:[
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLO%20SHOW%20No.%201.mp3?alt=media&token=2d1420b8-7d7e-419d-b032-4632fc2446ad"},
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLO%20SHOW%20NO.%202.mp3?alt=media&token=195cb4e7-2cfa-4817-aafc-0271523c0622"},
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLOSHOW%20No.3%20.mp3?alt=media&token=c2b5d154-65ac-4740-8ef5-c36db620a05f"}
      ]
        };
  },
  mounted(){
   this.$store.dispatch("loadShows")
   
    this.resize()
    window.onresize=this.resize

  },
  computed:{
    shows(){
      return this.$store.getters.getShows
    },
    minis(){
     return this.filterPods("mini")
    }
  },
  methods:{
    goHome(){
      this.$router.push({ name: 'Home' })
    },
   
   
    resize(){
      //this.pageScale=Math.min(1,window.innerHeight/this.$refs.container.clientHeight)
      //this.pageScale=window.innerHeight+" "+this.$refs.container.clientHeight
    },
  
    
  }
}
</script>

<style scoped>
a, a:visited{
    color:#fff;
    text-decoration: none;
}
a:hover{
    color: #FF9D06;
}

li{
     margin:15px;
     line-height: 150%;
}
</style>
