<template>
   <div ref="container" class="container" :style="'transform-origin:top center;transform:scale('+pageScale+')'">
      <header>
        <h1><span @click="goHome" style="cursor:pointer;">LAZLO STEIGENBERGER</span> PODCASTS</h1>  
      </header>
      
      <div class="podcastList">
        
        <div v-for="(t,ti) in types" :key="'t'+ti" class="podColumn">{{t.name}}
          <div v-for="(tr,tri) in filterPods(t.typ)" :key="'tt'+ti+tri">
            <div @click="playAudio(tr)" style='display: inline-block;;height:40px;height: 30px;line-height: 30px;' class="podcast">{{tr.name}}</div>
          </div>
        </div>
      </div>
      <div v-if="audioFile" style="display:flex;color:#fff;margin-bottom:20px">
        <div style="color:#ffe206;">
        Now Playing<br>
        {{audioName}}
        </div>
      <audio  style='display: inline-block; margin-left: 20px;flex-grow:1;'
                controls
                controlsList="nodownload"
                :ref="'playerX'"  
                :id="'playeridX'"
            >
                <source :src="audioFile" type="audio/mpeg" />
            </audio>
            </div>
      
       
     
         
    </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {
  
  },
  data() {
    return {
      audioFile:null,
      audioName:"XXXX",
      player:null,
      playerIndex:null,
      pageScale:1,
      startTime:null,
      distance:0,
      showConsole:false,
      types:{mini:{name:"Lazlo Minis",typ:"mini"},
            short:{name:"Lazlo Shorts",typ:"short"},
            long:{name:"Lazlo Elongated",typ:"long"}},
      podCasts:[
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLO%20SHOW%20No.%201.mp3?alt=media&token=2d1420b8-7d7e-419d-b032-4632fc2446ad"},
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLO%20SHOW%20NO.%202.mp3?alt=media&token=195cb4e7-2cfa-4817-aafc-0271523c0622"},
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLOSHOW%20No.3%20.mp3?alt=media&token=c2b5d154-65ac-4740-8ef5-c36db620a05f"}
      ]
        };
  },
  mounted(){
   this.$store.dispatch("loadShows")
    this.init()
    this.updateClock()
    this.resize()
    window.onresize=this.resize

  },
  computed:{
    shows(){
      return this.$store.getters.getShows
    },
    minis(){
     return this.filterPods("mini")
    }
  },
  methods:{
    goHome(){
      this.$router.push({ name: 'Home' })
    },
   
    playAudio(track){
      let file=track.file
      this.audioName=track.name.split("[")[0]
      if(this.audioFile){
       
        
         if(this.audioFile!=file){
           this.audioFile=null
        this.$nextTick(() => {
             this.audioFile=file  
             this.startPlaying()  
        })
         }else{
           this.audioFile=null
         }
      }else{
        this.audioFile=file
      }
      this.startPlaying()
      
    },
    startPlaying(){
      this.$nextTick(() => {
             if(this.$refs['playerX']){
                this.$refs.playerX.play(0)
              }
      })
    },
    filterPods(podType){
      if(this.shows){
      var newArray= this.shows.filter(function (el) {
        return el.type == podType
      });
      }
      return newArray
    },
    isPlaying(index){
      if(this.$refs['player'+index]){
        return !this.$refs['player'+index][0].paused
      }
      return false
    },
    resize(){
      this.pageScale=Math.min(1,window.innerHeight/this.$refs.container.clientHeight)
      //this.pageScale=window.innerHeight+" "+this.$refs.container.clientHeight
    },
    playShow(index){
     /* if(this.player){
        if(!this.player.paused){
          this.player.pause()
          this.showConsole=false
          return
        }
      }*/
      this.player=this.$refs['player'+index][0]
      if(!this.player.paused){
          this.player.pause()
          this.playerIndex=null
          this.showConsole=false
          return
      }
         
       this.playerIndex=index 
      this.player.play()
    
    },
    init(){
      this.startTime = new Date()
    },
    updateClock(){
       let currentTime = new Date()
        this.distance = ((currentTime - this.startTime) * 30 / 1000).toFixed(0)
        window.requestAnimationFrame(this.updateClock);
    },
    pad(num) {
        var s = "000000000000" + num
        return s.substr(s.length-10)
    }
  }
}

</script>
