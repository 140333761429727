<template>
   <div ref="container" class="container" :style="'transform-origin:top center;transform:scale('+pageScale+')'">
      <header>
        <h1><span @click="goHome" style="cursor:pointer;">LAZLO STEIGENBERGER</span> VIDEOS</h1>  
      </header>
      
      <div v-if="false" class="videoList">
        
       
          <div v-for="(tr,tri) in videos" :key="'tt'+tri" class="videoItem">
            <div  @click="playVideo(tr)" style='display: inline-block;height:40px;height: 30px;line-height: 30px;' class="podcast" >{{tr.name}}</div>
          </div>
        
      </div>
      <div v-if="videoFile" style="color:#fff;margin-bottom:20px">
        
        <iframe   :ref="'playerX'"  
                :id="'playeridX'"
                width="420" height="315"
                :src="url">
        </iframe>
      <div style="color:#ffe206;">
        Now Playing 
        {{videoName}}
       
        </div>
            </div>
      <div v-if="videos" style="width:720px;">
       <youtube-playlist 
          :videos="videos"
          :hideThumbnails="true"
          aspect-ratio="4:3"
        />
     </div>
         
    </div>
</template>

<script>
// @ is an alias to /src
 import URL from 'url-parse';
import { parseVideo, debounce } from './helpers';
import YouTubePlaylist from 'vue-youtube-playlist'

export default {
  name: 'Home',
  components: {
     'youtube-playlist': YouTubePlaylist
  },
  data() {
    return {
       arrayOfVideos:null,
       arrayOfVideosXX:[{
        url: 'https://www.youtube.com/watch?v=fwTsuFssg24',
        thumbnail: 'https://website.com/custom-thumbnail.jpg',
        name:"MY VID",
        config: {
            autoplay: 1,
            color: 'white',
            // You can use any params from https://developers.google.com/youtube/player_parameters#Manual_IFrame_Embeds
        }
       },
       {
        url:'https://www.youtube.com/watch?v=sn8Dw7PIDNk',
        name:"vid3"
        }],
      videoFile:null,
      videoName:"XXXX",
      video:null,
      player:null,
      playerIndex:null,
      pageScale:1,
      startTime:null,
      distance:0,
      showConsole:false,
      
        };
  },
  mounted(){
   this.$store.dispatch("loadVideos")
   
  
    this.resize()
    window.onresize=this.resize

  },
  computed:{
    
    url() {
      console.log("URL",this.videoFile)
                const url = new URL(this.videoFile.url, true);
                url.query = this.videoFile.config;
                console.log("URL-M",url)
                return url.toString();
            },
    videos(){
      return this.$store.getters.getVideos
    },
    shows(){
      return this.$store.getters.getShows
    },
    minis(){
     return this.filterPods("mini")
    }
  },
  methods:{
    goHome(){
      this.$router.push({ name: 'Home' })
    },
   
    playVideo(track){

      let file=track.url
      this.videoName=track.name
      if(this.videoFile){
       
        
         if(this.videoFile!=file){
           this.videoFile=null
        this.$nextTick(() => {
              this.videoFile=parseVideo(file)
             this.startPlaying()  
        })
         }else{
           this.videoFile=null
         }
      }else{
        this.videoFile=parseVideo(file)
        console.log(" this.videoFile", this.videoFile)
      }
      this.startPlaying()
      
    },
    startPlaying(){
      this.$nextTick(() => {
             if(this.$refs['playerX']){
              //  this.$refs.playerX.play(0)
              }
      })
    },
    filterPods(podType){
      if(this.shows){
      var newArray= this.shows.filter(function (el) {
        return el.type == podType
      });
      }
      return newArray
    },
    isPlaying(index){
      if(this.$refs['player'+index]){
        return !this.$refs['player'+index][0].paused
      }
      return false
    },
    resize(){
      this.pageScale=Math.min(1,window.innerHeight/this.$refs.container.clientHeight)
      //this.pageScale=window.innerHeight+" "+this.$refs.container.clientHeight
    },
    playShow(index){
     /* if(this.player){
        if(!this.player.paused){
          this.player.pause()
          this.showConsole=false
          return
        }
      }*/
      this.player=this.$refs['player'+index][0]
      if(!this.player.paused){
          this.player.pause()
          this.playerIndex=null
          this.showConsole=false
          return
      }
         
       this.playerIndex=index 
      this.player.play()
    
    }
    
   
  },
  watch:{
     
    }
}

</script>
<style scoped>
.videoItem{
  width:33%;
}
.videoList{
  display:flex;
}
.ytpl-playlist {
    position: relative;
    overflow-y: hidden;
    background-color:transparent;

}
</style>