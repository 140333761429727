<template>
   <div ref="container" class="container" :style="'transform-origin:top center;transform:scale('+pageScale+')'">
      <header>
        <h1><span @click="goHome" style="Cursor:pointer">STEIGENBERGER</span> SPACE CLOCK</h1>  
      </header>
      
      <section class="spaceclock">
        <h2 class="label top">ORBIT</h2>
        <p class="clock" id="clock">{{distance}}</p>
        <p class="label sub"><span class="km-label">KM</span></p>
        <p class="tagline">This is the distance you have travelled around the sun since opening this page</p>
      </section>
       
      <footer v-if="false">
        
           <div v-for="(a,i) in shows" :key="'p'+i">
          <div @click="playShow(i)" style='display: inline-block;;height:40px;height: 60px;line-height: 60px;' class="podcast">{{a.name}}</div>
                <audio v-show="isPlaying(i)" style='display: inline-block; margin-left: 20px;'
                controls
                controlsList="nodownload"
                :ref="'player'+i"
                :id="'playerid'+i"
            >
                <source :src="a.file" type="audio/mpeg" />
            </audio>
           </div>
            </footer>
         
    </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {
  
  },
  data() {
    return {
      audioFile:null,
      audioName:"XXXX",
      player:null,
      playerIndex:null,
      pageScale:1,
      startTime:null,
      distance:0,
      showConsole:false,
      types:{mini:{name:"Lazlo Minis",typ:"mini"},
            short:{name:"Lazlo Shorts",typ:"short"},
            long:{name:"Lazlo Elongated",typ:"long"}},
      podCasts:[
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLO%20SHOW%20No.%201.mp3?alt=media&token=2d1420b8-7d7e-419d-b032-4632fc2446ad"},
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLO%20SHOW%20NO.%202.mp3?alt=media&token=195cb4e7-2cfa-4817-aafc-0271523c0622"},
        {audioFile:"https://firebasestorage.googleapis.com/v0/b/lazlo-show.appspot.com/o/LAZLOSHOW%20No.3%20.mp3?alt=media&token=c2b5d154-65ac-4740-8ef5-c36db620a05f"}
      ]
        };
  },
  mounted(){
   this.$store.dispatch("loadShows")
    this.init()
    this.updateClock()
    this.resize()
    window.onresize=this.resize

  },
  computed:{
    shows(){
      return this.$store.getters.getShows
    },
    minis(){
     return this.filterPods("mini")
    }
  },
  methods:{
    showBook(){
      this.$router.push({ path: 'Book' })
    },
    goHome(){
      this.$router.push({ name: 'Home' })
    },
    playAudio(track){
      let file=track.file
      this.audioName=track.name.split("[")[0]
      if(this.audioFile){
       
        
         if(this.audioFile!=file){
           this.audioFile=null
        this.$nextTick(() => {
             this.audioFile=file  
             this.startPlaying()  
        })
         }else{
           this.audioFile=null
         }
      }else{
        this.audioFile=file
      }
      this.startPlaying()
      
    },
    startPlaying(){
      this.$nextTick(() => {
             if(this.$refs['playerX']){
                this.$refs.playerX.play(0)
              }
      })
    },
    filterPods(podType){
      if(this.shows){
      var newArray= this.shows.filter(function (el) {
        return el.type == podType
      });
      }
      return newArray
    },
    isPlaying(index){
      if(this.$refs['player'+index]){
        return !this.$refs['player'+index][0].paused
      }
      return false
    },
    resize(){
      this.pageScale=Math.min(1,window.innerHeight/this.$refs.container.clientHeight)
      //this.pageScale=window.innerHeight+" "+this.$refs.container.clientHeight
    },
    playShow(index){
     /* if(this.player){
        if(!this.player.paused){
          this.player.pause()
          this.showConsole=false
          return
        }
      }*/
      this.player=this.$refs['player'+index][0]
      if(!this.player.paused){
          this.player.pause()
          this.playerIndex=null
          this.showConsole=false
          return
      }
         
       this.playerIndex=index 
      this.player.play()
    
    },
    init(){
      this.startTime = new Date()
    },
    updateClock(){
       let currentTime = new Date()
        this.distance = ((currentTime - this.startTime) * 30 / 1000).toFixed(0)
        window.requestAnimationFrame(this.updateClock);
    },
    pad(num) {
        var s = "000000000000" + num
        return s.substr(s.length-10)
    }
  }
}

</script>
